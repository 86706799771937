import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/share",
    name: "share",
    component: () => import("../share/share1"),
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    meta: {
      requireAuth: true,
      title: "心血管风险评估问卷",
    },
  },
  {
    path: "/answer/heart/:token",
    name: "answer/heart",
    component: () => import("../views/welcomePage.vue"),
    meta: {
      requireAuth: true,
      title: "心血管风险评估问卷",
    },
  },
  {
    path: "/twopage",
    name: "twopage",
    component: () => import("../views/twoPage.vue"),
    meta: {
      requireAuth: true,
      title: "心血管风险评估问卷",
    },
  },
  {
    path: "/threepage",
    name: "threepage",
    component: () => import("../views/threePage.vue"),
    meta: {
      requireAuth: true,
      title: "心血管风险评估问卷",
    },
  },
  {
    path: "/fourpage",
    name: "fourpage",
    component: () => import("../views/fourPage.vue"),
    meta: {
      requireAuth: true,
      title: "心血管风险评估问卷",
    },
  },
  {
    path: "/fivepage",
    name: "fivepage",
    component: () => import("../views/fivePage.vue"),
    meta: {
      requireAuth: true,
      title: "心血管风险评估问卷",
    },
  },
  {
    path: "/sixpage",
    name: "sixpage",
    component: () => import("../views/sixPage.vue"),
    meta: {
      requireAuth: true,
      title: "心血管风险评估问卷",
    },
  },
  {
    path: "/sevenpage",
    name: "sevenpage",
    component: () => import("../views/sevenPage.vue"),
    meta: {
      requireAuth: true,
      title: "心血管风险评估问卷",
    },
  },
  {
    path: "/eightpage",
    name: "eightpage",
    component: () => import("../views/eightPage.vue"),
    meta: {
      requireAuth: true,
      title: "心血管风险评估问卷",
    },
  },
  {
    path: "/ninepage",
    name: "ninepage",
    component: () => import("../views/ninePage.vue"),
    meta: {
      requireAuth: true,
      title: "心血管风险评估问卷",
    },
  },
  {
    path: "/tenpage",
    name: "tenpage",
    component: () => import("../views/tenPage.vue"),
    meta: {
      requireAuth: true,
      title: "心血管风险评估问卷",
    },
  },
  {
    path: "/elevenpage",
    name: "elevenpage",
    component: () => import("../views/elevenPage.vue"),
    meta: {
      requireAuth: true,
      title: "心血管风险评估问卷",
    },
  },
  {
    path: "/twelvepage",
    name: "twelvepage",
    component: () => import("../views/twelvePage.vue"),
    meta: {
      requireAuth: true,
      title: "心血管风险评估问卷",
    },
  },
  {
    path: "/thirteenpage",
    name: "thirteenpage",
    component: () => import("../views/thirteenPage.vue"),
    meta: {
      requireAuth: true,
      title: "心血管风险评估问卷",
    },
  },
  {
    path: "/fourteenpage",
    name: "fourteenpage",
    component: () => import("../views/fourteenPage.vue"),
    meta: {
      requireAuth: true,
      title: "心血管风险评估问卷",
    },
  },
  {
    path: "/fifteenpage",
    name: "fifteenpage",
    component: () => import("../views/fifteenPage.vue"),
    meta: {
      requireAuth: true,
      title: "心血管风险评估问卷",
    },
  },
  {
    path: "/sixteenpage",
    name: "sixteenpage",
    component: () => import("../views/sixteenPage.vue"),
    meta: {
      requireAuth: true,
      title: "心血管风险评估问卷",
    },
  },
  {
    path: "/seventeenpage",
    name: "seventeenpage",
    component: () => import("../views/seventeenPage.vue"),
    meta: {
      requireAuth: true,
      title: "心血管风险评估问卷",
    },
  },
  {
    path: "/eighteenpage",
    name: "eighteenpage",
    component: () => import("../views/eighteenPage.vue"),
    meta: {
      requireAuth: true,
      title: "心血管风险评估问卷",
    },
  },
  {
    path: "/nineteenpage",
    name: "nineteenpage",
    component: () => import("../views/nineteenPage.vue"),
    meta: {
      requireAuth: true,
      title: "心血管风险评估问卷",
    },
  },
  {
    path: "/twentypage",
    name: "twentypage",
    component: () => import("../views/twentyPage.vue"),
    meta: {
      requireAuth: true,
      title: "心血管风险评估问卷",
    },
  },
  {
    path: "/twentyonepage",
    name: "twentyonepage",
    component: () => import("../views/twentyonePage.vue"),
    meta: {
      requireAuth: true,
      title: "心血管风险评估问卷",
    },
  },
  {
    path: "/twentytwopage",
    name: "twentytwopage",
    component: () => import("../views/twentytwoPage.vue"),
    meta: {
      requireAuth: true,
      title: "心血管风险评估问卷",
    },
  },
  {
    path: "/twentythreepage",
    name: "twentythreepage",
    component: () => import("../views/twentythreePage.vue"),
    meta: {
      requireAuth: true,
      title: "心血管风险评估问卷",
    },
  },
  {
    path: "/newsixpage",
    name: "newsixpage",
    component: () => import("../views/newsixPage.vue"),
    meta: {
      requireAuth: true,
      title: "心血管风险评估问卷",
    },
  },
  {
    path: "/resultpage",
    name: "resultpage",
    component: () => import("../views/result.vue"),
    meta: {
      requireAuth: true,
      title: "心血管风险评估问卷",
    },
  },
  //糖尿病风险
  {
    path: "/abouts",
    name: "abouts",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/answerGlu/AboutView.vue"
      ),
    meta: {
      title: "糖尿病风险筛查工具",
      requireAuth: true,
    },
  },
  {
    path: "/answer/glu/:token",
    name: "answer/glu",
    component: () => import("../views/answerGlu/welcomePage.vue"),
    meta: {
      title: "糖尿病风险筛查工具",
      requireAuth: true,
    },
  },
  {
    path: "/twopages",
    name: "twopages",
    component: () => import("../views/answerGlu/twoPage.vue"),
    meta: {
      title: "糖尿病风险筛查工具",
      requireAuth: true,
    },
  },
  {
    path: "/threepages",
    name: "threepages",
    component: () => import("../views/answerGlu/threePage.vue"),
    meta: {
      title: "糖尿病风险筛查工具",
      requireAuth: true,
    },
  },
  {
    path: "/fourpages",
    name: "fourpages",
    component: () => import("../views/answerGlu/fourPage.vue"),
    meta: {
      title: "糖尿病风险筛查工具",
      requireAuth: true,
    },
  },
  {
    path: "/fivepages",
    name: "fivepages",
    component: () => import("../views/answerGlu/fivePage.vue"),
    meta: {
      title: "糖尿病风险筛查工具",
      requireAuth: true,
    },
  },
  {
    path: "/sixpages",
    name: "sixpages",
    component: () => import("../views/answerGlu/sixPage.vue"),
    meta: {
      title: "糖尿病风险筛查工具",
      requireAuth: true,
    },
  },
  {
    path: "/sevenpages",
    name: "sevenpages",
    component: () => import("../views/answerGlu/sevenPage.vue"),
    meta: {
      title: "糖尿病风险筛查工具",
      requireAuth: true,
    },
  },
  {
    path: "/eightpages",
    name: "eightpages",
    component: () => import("../views/answerGlu/eightPage.vue"),
    meta: {
      title: "糖尿病风险筛查工具",
      requireAuth: true,
    },
  },
  {
    path: "/ninepages",
    name: "ninepages",
    component: () => import("../views/answerGlu/ninePage.vue"),
    meta: {
      title: "糖尿病风险筛查工具",
      requireAuth: true,
    },
  },
  {
    path: "/tenpages",
    name: "tenpages",
    component: () => import("../views/answerGlu/tenPage.vue"),
    meta: {
      title: "糖尿病风险筛查工具",
      requireAuth: true,
    },
  },
  {
    path: "/elevenpages",
    name: "elevenpages",
    component: () => import("../views/answerGlu/elevenPage.vue"),
    meta: {
      title: "糖尿病风险筛查工具",
      requireAuth: true,
    },
  },
  {
    path: "/twelvepages",
    name: "twelvepages",
    component: () => import("../views/answerGlu/twelvePage.vue"),
    meta: {
      title: "糖尿病风险筛查工具",
      requireAuth: true,
    },
  },
  {
    path: "/thirteenpages",
    name: "thirteenpages",
    component: () => import("../views/answerGlu/thirteenPage.vue"),
    meta: {
      title: "糖尿病风险筛查工具",
      requireAuth: true,
    },
  },
  {
    path: "/resultpages",
    name: "resultpages",
    component: () => import("../views/answerGlu/resultPage.vue"),
    meta: {
      title: "糖尿病风险筛查工具",
      requireAuth: true,
    },
  },
  //app下载
  {
    path: "/appLoad",
    name: "appLoad",
    component: () => import("../views/appLoad/index.vue"),
    meta: {
      requireAuth: true,
      title: "晶捷健康",
    },
  },
  //英文晶捷健康app下载
  {
    path: "/enAppLoad",
    name: "enAppLoad",
    component: () => import("../views/enAppLoad/index.vue"),
    meta: {
      requireAuth: true,
      title: "eaglenosHealth",
    },
  },
  {
    path: "/cgmAppLoad",
    name: "cgmAppLoad",
    component: () => import("../views/CGMonload/index.vue"),
    meta: {
      requireAuth: true,
      title: "APP下载",
    },
  },
  //晶捷健康用户注销
  {
    path: "/appLogOut",
    name: "appLogOut",
    component: () => import("../views/appLogOut/index.vue"),
    meta: {
      requireAuth: true,
      title: "注销账号",
    },
  },
  //cgm app用户注销
  {
    path: "/cgmLogOut",
    name: "cgmLogOut",
    component: () => import("../views/cgmLogOut/index.vue"),
    meta: {
      requireAuth: true,
      title: "注销账号",
    },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
export default router;
