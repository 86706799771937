import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage // 注意
    })
  ],
  state: {
    allData:{}
  },
  getters: {},
  mutations: {
    addAllData(state,data){
      state.allData[data.name]=data.value
      // console.log(state.allData);
    }
  },
  actions: {},
  modules: {}
})