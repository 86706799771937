import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './rem/rem'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);
import {
  Link
} from 'element-ui';

import vueTouch from 'kim-vue-touch';
Vue.use(vueTouch)

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(Link)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// router.beforeEach((to,from,next)=>{
//   if (to.meta.requireAuth) {
//     if (sessionStorage.getItem('token')) {
//       next()
//     }else{
//       next({
//         path:'/answer/heart/:token'
//       })
//     }
//   }else{
//     next()
//   }
// })

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})