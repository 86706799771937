<template>
  <div class="swiper-content" ref="swiperContent">
    <el-carousel :height="height" direction="vertical" :autoplay="false">
    <el-carousel-item v-for="item in 3" :key="item">
      <h3 class="medium">{{ item }}</h3>
    </el-carousel-item>
  </el-carousel>
  </div>
</template>
<script>
 export default {
  mounted() {
    let domH = this.$refs.swiperContent.clientHeight
    this.height = domH.toString() + 'px'
  },
  data() {
    return {
      height: '300'
    }
  }
 }
</script>

<style>
.swiper-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
</style>